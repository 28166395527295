import React from "react"
import { graphql, Link } from "gatsby";

class WorkButtons extends React.Component {

  render() {    
    const color = this.props.color ? this.props.color : "#FFFFFF";
    return (
      <div className="work-buttons">
        {this.props.viewLink !== "" && <a href={this.props.viewLink} className="work-button link" target="_blank" rel="noopener noreferrer">
          <svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 59.669 59.669">
            <path fill={color}
              d="M55.868,3.8c-2.446-2.446-5.709-3.793-9.189-3.793c-3.479,0-6.743,1.347-9.189,3.793l-9.07,9.07c-3.275,3.275-4.42,7.876-3.461,12.09c-0.941-0.212-1.91-0.333-2.9-0.333c-3.479,0-6.743,1.347-9.188,3.793L3.8,37.491c-5.066,5.066-5.066,13.312,0,18.378c2.446,2.446,5.709,3.793,9.189,3.793s6.743-1.347,9.189-3.793l9.07-9.07c3.275-3.275,4.42-7.876,3.461-12.09c0.941,0.212,1.91,0.333,2.9,0.333c3.48,0,6.744-1.347,9.189-3.793l9.071-9.07C60.936,17.112,60.936,8.867,55.868,3.8z M28.42,43.97l-9.07,9.07c-1.69,1.69-3.95,2.621-6.361,2.621s-4.67-0.931-6.361-2.621c-3.507-3.508-3.507-9.214,0-12.722l9.071-9.07c1.69-1.69,3.949-2.621,6.36-2.621c1.724,0,3.366,0.483,4.782,1.372l-7.752,7.752c-0.781,0.781-0.781,2.047,0,2.828c0.39,0.391,0.902,0.586,1.414,0.586s1.024-0.195,1.414-0.586l7.753-7.753C31.852,36.3,31.44,40.949,28.42,43.97z M53.04,19.35l-9.071,9.07c-1.69,1.69-3.949,2.621-6.361,2.621c-1.724,0-3.365-0.483-4.781-1.372l7.752-7.752c0.781-0.781,0.781-2.047,0-2.828c-0.78-0.781-2.048-0.781-2.828,0l-7.753,7.753c-2.182-3.474-1.77-8.122,1.25-11.143l9.07-9.07c1.69-1.69,3.95-2.621,6.361-2.621c2.412,0,4.67,0.931,6.361,2.621s2.621,3.949,2.621,6.361C55.661,15.401,54.73,17.66,53.04,19.35z" />
          </svg>
          <span className="hover-label">View Site</span>
        </a>}

        {this.props.codeLink !== "" && <a href={this.props.codeLink} className="work-button code" target="_blank" rel="noopener noreferrer">
          <span className="hover-label">See Code</span>
          <svg version="1.1" id="Capa_1" x="0px" y="0px" width="522.468px" height="522.469px" viewBox="0 0 522.468 522.469">
            <g>
              <g>
                <path fill={color}
                  d="M325.762,70.513l-17.706-4.854c-2.279-0.76-4.524-0.521-6.707,0.715c-2.19,1.237-3.669,3.094-4.429,5.568L190.426,440.53c-0.76,2.475-0.522,4.809,0.715,6.995c1.237,2.19,3.09,3.665,5.568,4.425l17.701,4.856c2.284,0.766,4.521,0.526,6.71-0.712c2.19-1.243,3.666-3.094,4.425-5.564L332.042,81.936c0.759-2.474,0.523-4.808-0.716-6.999C330.088,72.747,328.237,71.272,325.762,70.513z" />
                <path fill={color}
                  d="M166.167,142.465c0-2.474-0.953-4.665-2.856-6.567l-14.277-14.276c-1.903-1.903-4.093-2.857-6.567-2.857s-4.665,0.955-6.567,2.857L2.856,254.666C0.95,256.569,0,258.759,0,261.233c0,2.474,0.953,4.664,2.856,6.566l133.043,133.044c1.902,1.906,4.089,2.854,6.567,2.854s4.665-0.951,6.567-2.854l14.277-14.268c1.903-1.902,2.856-4.093,2.856-6.57c0-2.471-0.953-4.661-2.856-6.563L51.107,261.233l112.204-112.201C165.217,147.13,166.167,144.939,166.167,142.465z" />
                <path fill={color}
                  d="M519.614,254.663L386.567,121.619c-1.902-1.902-4.093-2.857-6.563-2.857c-2.478,0-4.661,0.955-6.57,2.857l-14.271,14.275c-1.902,1.903-2.851,4.09-2.851,6.567s0.948,4.665,2.851,6.567l112.206,112.204L359.163,373.442c-1.902,1.902-2.851,4.093-2.851,6.563c0,2.478,0.948,4.668,2.851,6.57l14.271,14.268c1.909,1.906,4.093,2.854,6.57,2.854c2.471,0,4.661-0.951,6.563-2.854L519.614,267.8c1.903-1.902,2.854-4.096,2.854-6.57C522.468,258.755,521.517,256.565,519.614,254.663z" />
              </g>
            </g>
          </svg>
        </a>}

        {!this.props.single && <Link to={this.props.storyLink} className="work-button story" >
          <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 297.001 297.001">
            <g>
              <g>
                <path fill={color}
                  d="M287.034,60.873l-20.819-0.001V39.321c0-4.934-3.61-9.126-8.49-9.856c-0.852-0.128-21.134-3.074-45.557,1.37c-27.227,4.954-48.941,17.171-63.668,35.64c-14.728-18.469-36.442-30.686-63.668-35.64c-24.424-4.443-44.706-1.498-45.557-1.37c-4.88,0.731-8.49,4.923-8.49,9.856v21.551H9.966C4.463,60.872,0,65.335,0,70.839v187.805c0,3.227,1.562,6.254,4.193,8.124s6.004,2.35,9.051,1.288c0.748-0.259,75.431-25.747,131.12-0.345c2.628,1.199,5.645,1.199,8.273,0c55.533-25.33,130.376,0.088,131.12,0.345c1.068,0.372,2.174,0.555,3.276,0.555c2.043,0,4.065-0.628,5.775-1.842c2.631-1.87,4.193-4.897,4.193-8.124V70.84C297,65.336,292.538,60.873,287.034,60.873z M19.933,245.309V80.805h10.852v132.726c0,2.896,1.267,5.646,3.458,7.539c2.191,1.893,5.105,2.742,7.969,2.319c0.55-0.08,43.846-6.024,75.478,15.679C78.725,232.405,39.727,240.112,19.933,245.309z M138.534,230.08c-13.932-12.588-32.079-21.1-53.702-25.034c-10.406-1.894-20.06-2.446-27.78-2.446c-2.292,0-4.414,0.049-6.333,0.126V48.473h-0.001c19.155-0.864,65.752,1.184,87.816,38.587V230.08z M158.466,87.061c21.985-37.243,68.655-39.384,87.816-38.563v154.228c-8.383-0.338-20.62-0.136-34.114,2.32c-21.623,3.934-39.77,12.445-53.702,25.034V87.061z M179.277,239.074c31.636-21.716,74.955-15.766,75.495-15.686c2.871,0.431,5.783-0.413,7.981-2.305c2.198-1.894,3.462-4.65,3.462-7.552V80.806h10.852v164.503C257.267,240.11,218.253,232.4,179.277,239.074z" />
              </g>
            </g>
          </svg>
          <span className="hover-label">Read More</span></Link>}
      </div>
    );
  }
}

export default WorkButtons